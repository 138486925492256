import { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import clsx from 'clsx'

import usePCWindow from 'hooks/usePCWindow'

import { logoIcon, logoIconSize, logoIconCollapsed, logoIconCollapsedSize } from './data'
import { ScreenSize } from 'constants/media-constanst'

import styles from './logo.module.scss'

interface ILogo {
  isResponsive?: boolean
  logoMod?: string
}

interface ICollapsedLogo {
  src: string
  width: number
  height: number
}

const Logo: FC<ILogo> = ({ isResponsive, logoMod }) => {
  const logoSize = usePCWindow(ScreenSize.big) ? logoIconSize.big : logoIconSize.small
  const isLogoCollapsed = usePCWindow(ScreenSize.mobile)

  const collapsedLogoProps = {
    src: isLogoCollapsed ? logoIconCollapsed : logoIcon,
    width: isLogoCollapsed ? logoIconCollapsedSize.width : logoSize.width,
    height: isLogoCollapsed ? logoIconCollapsedSize.height : logoSize.height,
  }

  const CollapsedLogo: FC<ICollapsedLogo> = (props) => {
    return (
      <Link
        className={clsx(styles.logo, logoMod, {
          [styles.logoCollapsed]: isLogoCollapsed,
        })}
        href="/"
      >
        <Image {...props} alt="Medpoint | Образовательный портал для врачей" />
      </Link>
    )
  }

  return isResponsive ? (
    CollapsedLogo(collapsedLogoProps)
  ) : (
    <Link className={clsx(styles.logo, logoMod)} href="/">
      <Image src={logoIcon} width={logoSize.width} height={logoSize.height} alt="" />
    </Link>
  )
}

export default Logo
