import { FC, useRef } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

import SearchField from '../search-field/search-field'
import useOnClickOutside from 'hooks/useClickOutside'
import { useAuth } from 'store/auth/useAuth'

import style from './navbar-list.module.scss'
interface INavbarListItems {
  isHamburgerOpen: boolean
  pathname: string
  closeMenu: () => void
  navBarItems: {
    link: string
    text: string
    isNew?: boolean
    activeRoutes?: string[]
    noAuthLink?: string
  }[]
}

const NavbarListItems: FC<INavbarListItems> = ({
  isHamburgerOpen,
  closeMenu,
  pathname,
  navBarItems,
}: INavbarListItems) => {
  const listRef = useRef(null)
  const { isNotAuthorized } = useAuth()
  useOnClickOutside(isHamburgerOpen, listRef, closeMenu)

  return (
    <>
      {isHamburgerOpen && <div className={style.navWrapper} onClick={closeMenu}></div>}
      <ul
        className={clsx(style.list, {
          [style.listActive]: isHamburgerOpen,
        })}
        ref={listRef}
      >
        <li className={style.searchField}>
          <SearchField closeMenu={closeMenu} />
        </li>
        {navBarItems.map(({ link, text, isNew, activeRoutes, noAuthLink }) => {
          const linkItem = noAuthLink && isNotAuthorized ? noAuthLink : link
          const isActive =
            link === pathname || activeRoutes?.some((route) => pathname.includes(route))
          const id = link.replace('/', '')

          return (
            <li key={text} className={style.listElement}>
              <Link
                className={clsx(style.listItem, {
                  [style.listItemActive]: isActive,
                  [style.listItemNew]: isNew,
                })}
                href={linkItem}
                id={id}
                prefetch={false}
              >
                {text}
                {isNew && !isActive && <span className={style.new}>новое</span>}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default NavbarListItems
