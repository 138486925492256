import { FC } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import Link from 'next/link'

import { buttonIdAcceptCookie } from 'constants/clickableElementsId'
import { PROD_STATIC_PATH } from 'constants/data'

import styles from './cookies.module.scss'

interface ICookiesModal {
  closeCookie: () => void
}

const CookiesModal: FC<ICookiesModal> = ({ closeCookie }) => {
  const router = useRouter()
  return (
    <div
      className={clsx(styles.contentWrapper, {
        [styles.altPosition]: router.asPath.includes('geriatrics-school'),
      })}
    >
      <p>
        Пользуясь нашим сайтом, вы соглашаетесь с тем, что&nbsp;
        <Link
          className={styles.link}
          target="_blank"
          href={`${PROD_STATIC_PATH}/privacy.pdf`}
          prefetch={false}
        >
          мы&nbsp;используем&nbsp;cookies
        </Link>
        &nbsp;🍪
      </p>
      <button className={styles.btn} onClick={closeCookie} id={buttonIdAcceptCookie}>
        Принимаю
      </button>
    </div>
  )
}

export default CookiesModal
