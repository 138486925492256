import { FC, RefObject, memo, useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

const RecLine = dynamic(() => import('./rec-line/rec-line'), { loading: () => PreloadLine })
const FillProfileLine = dynamic(() => import('./fill-profile-line/fill-profile-line'), {
  loading: () => PreloadLine,
})
import { useAuth } from 'store/auth/useAuth'
import useActiveWebinars from 'store/navbar/useActiveWebinars'
import useLineStatus from 'store/navbar/useLineStatus'
import useActivePopupStatus from 'store/common/useActivePopupStatus'
import useNavbarHeight from 'store/navbar/useNavbarHeight'
import usePCWindow from 'hooks/usePCWindow'
import useDeviceOrientation from 'hooks/useDeviceOrientation'
// import NmoCoursesLine from './nmo-courses-line/nmo-courses-line'

import { getHashFromUrl } from 'utils/getURLData'
import { popupName } from 'constants/auth/auth-data'
import { ScreenSize } from 'constants/media-constanst'
import { lineHeight } from '../data'
import { NavLineStatus, NavLine } from 'constants/types/navbar.types'

interface INavbarBottom {
  isSearchOpen: boolean
  navRef: RefObject<HTMLDivElement>
  noLines?: boolean
  isCongress?: boolean
}

const PreloadLine = <div style={{ height: lineHeight }}></div>

const NavbarBottom: FC<INavbarBottom> = ({
  noLines = false,
  isSearchOpen,
  navRef,
  isCongress,
}: INavbarBottom) => {
  const { asPath } = useRouter()
  const { hashPopupStatus } = useActivePopupStatus()
  const { setNavbarHeight } = useNavbarHeight()
  const { lineStatus, setLineStatus } = useLineStatus()
  const { isAuthorized, isNotAuthorized, userProfile, isLoaded, isFilledUser, isSponsor } =
    useAuth()
  const {
    activeWebinars,
    isLoaded: isActiveWebinarLoaded,
    isPageWithActiveWebinar,
  } = useActiveWebinars()
  const isPC = usePCWindow(ScreenSize.big)
  const orientation = useDeviceOrientation()

  // const isDozatorDisabled = lineStatus[NavLineStatus.DOZATOR_LINE_DISABLED]

  const handleCloseLine = (line: NavLineStatus) => () => setLineStatus({ [line]: true })

  useEffect(() => {
    const hash = getHashFromUrl(asPath)
    const isFullScreenPopup = hash && hash in popupName

    const isRecLineActive =
      !noLines &&
      !isCongress &&
      !isPageWithActiveWebinar &&
      !isFullScreenPopup &&
      Boolean(activeWebinars?.length) &&
      !lineStatus.REC_LINE_CLOSED

    if (isRecLineActive) {
      setLineStatus({ [NavLineStatus.ACTIVE_LINE]: NavLine.REC_LINE })
      return
    }

    if (isAuthorized && !isLoaded) return

    const isProfileLineActive =
      isAuthorized &&
      !noLines &&
      !isFilledUser &&
      !isSponsor &&
      !lineStatus.PROFILE_LINE_CLOSED &&
      !isPageWithActiveWebinar
    if (isProfileLineActive) {
      setLineStatus({ [NavLineStatus.ACTIVE_LINE]: NavLine.PROFILE_LINE })
      return
    }

    setLineStatus({ [NavLineStatus.ACTIVE_LINE]: NavLine.ALL_CLOSED })
  }, [
    activeWebinars,
    isAuthorized,
    isLoaded,
    isNotAuthorized,
    asPath,
    noLines,
    isPageWithActiveWebinar,
    userProfile,
    lineStatus.REC_LINE_CLOSED,
    lineStatus.PROFILE_LINE_CLOSED,
  ])

  useEffect(() => {
    const height = navRef?.current?.offsetHeight

    if (height) {
      setNavbarHeight(height)
    }
  }, [
    Boolean(lineStatus.ACTIVE_LINE),
    isActiveWebinarLoaded,
    Boolean(activeWebinars),
    isAuthorized,
    Boolean(hashPopupStatus),
    isPC,
    isSearchOpen,
    orientation,
  ])
  if (noLines) return <></>
  return (
    <>
      {lineStatus[NavLineStatus.ACTIVE_LINE] === NavLine.REC_LINE && (
        <RecLine handleClose={handleCloseLine(NavLineStatus.REC_LINE_CLOSED)} />
      )}
      {lineStatus[NavLineStatus.ACTIVE_LINE] === NavLine.PROFILE_LINE && (
        <FillProfileLine handleClose={handleCloseLine(NavLineStatus.PROFILE_LINE_CLOSED)} />
      )}
      {/* <NmoCoursesLine /> */}
    </>
  )
}

export default memo(NavbarBottom)
