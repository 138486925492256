import { FC, useEffect, useRef } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import useAlert from 'store/common/useAlert'

import { STATIC_URL } from 'constants/data'
import { IAlertMessage } from 'constants/alert'

import commonStyles from '../alerts.module.scss'
import styles from './alert-base.module.scss'

const iconSuccess = `${STATIC_URL}/icons/icon_success_green.svg`
const iconWarning = `${STATIC_URL}/icons/icon_warning.svg`

interface IAlertBase {
  message?: IAlertMessage
  isError?: boolean
}

const AlertBase: FC<IAlertBase> = ({ message, isError }: IAlertBase) => {
  const timer = useRef<any>(null)
  const { deleteSingleAlert } = useAlert()

  useEffect(() => {
    timer.current = setTimeout(() => {
      message && deleteSingleAlert(message)
    }, 5000)

    return () => clearTimeout(timer.current)
  }, [])

  return (
    <div
      className={clsx(commonStyles.popupWrapper, styles.popupWrapper, {
        [styles.alertError]: isError,
      })}
    >
      <div className={clsx(commonStyles.notificationBG, { [styles.bgError]: isError })} />
      <Image
        src={isError ? iconWarning : iconSuccess}
        className={styles.img}
        width="41"
        height="41"
        alt={isError ? 'Произошла ошибка' : 'Изменения успешно сохранены'}
      />
      <p className={styles.text}>{message?.text || 'Изменения успешно сохранены'}</p>
    </div>
  )
}

export default AlertBase
