import { FC } from 'react'
import Image, { ImageProps } from 'next/image'
import clsx from 'clsx'

import Button from 'components/parts/button/button'
import WidthLimiter from 'containers/width-limiter/width-limiter'
import BtnClose from 'public/img/filters/icon_close.svg'
import useDisableAMO from 'hooks/useDisableAmo'

import { STATIC_URL } from 'constants/data'

import styles from './sticky-banner-wrapper.module.scss'

const imgPath = {
  icon_close: `${STATIC_URL}/banners/close_banner_icon.svg`,
}

interface IStickyBannerWrapper {
  title: string
  closeBanner: () => void
  bannerImageData?: ImageProps
  btnCloseMod?: string
  buttonData: {
    buttonText: string
    buttonClickHandler: () => void
    icon?: ImageProps
    iconMod?: string
  }
}

const StickyBannerWrapper: FC<IStickyBannerWrapper> = ({
  bannerImageData,
  title,
  buttonData,
  closeBanner,
  btnCloseMod,
}) => {
  useDisableAMO()

  return (
    <div className={styles.banner}>
      <WidthLimiter noBottomPadding noTopPadding modificator={styles.wrapper}>
        <div className={styles.content}>
          {bannerImageData && <Image className={styles.image} {...bannerImageData} />}
          <p className={styles.txt}>{title}</p>
          <div className={styles.btnWrapper}>
            <Button onClick={buttonData.buttonClickHandler} modificator={styles.btn}>
              <>
                {buttonData.buttonText}
                {buttonData.icon && <Image {...buttonData.icon} className={buttonData.iconMod} />}
              </>
            </Button>
          </div>
          <div className={styles.btnCloseWrapper} onClick={closeBanner}>
            <BtnClose
              className={clsx(styles.btnClose, {
                [btnCloseMod as string]: btnCloseMod,
              })}
            />
          </div>
        </div>
      </WidthLimiter>
    </div>
  )
}

export default StickyBannerWrapper
