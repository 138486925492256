import { FC, FunctionComponent, ReactNode, useEffect, useState } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import clsx from 'clsx'

import Navbar from 'components/global/navbar/navbar'
const Footer = dynamic(() => import('components/global/footer/footer'))
import Cookies from 'components/popups/cookies/cookies'
// import AttentionPopup from 'components/popups/popup-attention/popup-attention'
const NoInternetConnection = dynamic(
  () => import('components/popups/no-internet-connection/no-internet-connection')
)
const RatingTable = dynamic(
  () => import('components/webinar/webinar-active/star-rating/rating-table/rating-table')
)
const PopupBrokenMail = dynamic(
  () => import('components/popups/popup-broken-mail-wrap/popup-broken-mail-wrap')
)
import HashPopup from 'components/fullscreen-popups/hash-popup/hash-popup'
import BannerTelegramBottom from 'components/banners/banner-telegram-bottom/banner-telegram-bottom'
import AlertWrapper from '../alert-wrapper/alert-wrapper'
import Auth from 'store/auth'
import useTitle from 'store/common/useTitle'
import useNavbarHeight from 'store/navbar/useNavbarHeight'
import useInternetConnection from 'store/common/useInternetConnection'
import useActiveWebinars from 'store/navbar/useActiveWebinars'
import useGetOriginUser from 'hooks/useGetOriginUser'
import useUnratedWebinar from 'store/common/useUnratedWebinar'
import useBannerTelegramBottom from 'store/popups/useBannerTelegramBottom'

import { Events } from 'constants/events'
import { ORIGIN_PAST } from 'constants/analytic-data'
import { YaMetricHit } from 'utils/metrics/analyticMethods'

import styles from './main-layout.module.scss'

interface IMainLayout {
  noFooter?: boolean
  noHeader?: boolean
  headerInner?: FunctionComponent
  isSmooth?: boolean
  mainPage?: boolean
  noLines?: boolean
  isAbsolute?: boolean
  noAttention?: boolean
  noBtnUp?: boolean
  isScreenHeight?: boolean
  btnUpModificator?: string
  noInformLine?: boolean
  modificator?: string
  footerModificator?: string
  headerModificator?: string
  noRaitingTable?: boolean
  isNoAuth?: boolean
  children: ReactNode
  noNavbarItems?: boolean
}

const MainLayout: FC<IMainLayout> = ({
  children,
  noFooter,
  noHeader,
  headerInner,
  mainPage,
  noLines,
  isSmooth,
  isAbsolute,
  btnUpModificator,
  isScreenHeight,
  noInformLine,
  modificator,
  footerModificator,
  headerModificator,
  noRaitingTable,
  noAttention,
  isNoAuth,
  noNavbarItems,
}) => {
  const [isInformLineHide, setInformLineHide] = useState(true)
  const { navbarHeight } = useNavbarHeight()
  const { currentTitle } = useTitle()
  const {
    isAuthorized,
    userProfile: { informData },
  } = Auth.useAuth()
  const { isOnline } = useInternetConnection()
  const { isPageWithActiveWebinar, isLoaded } = useActiveWebinars()
  const { unratedWebinar } = useUnratedWebinar()
  const { isBannerTelegramBottomOpen } = useBannerTelegramBottom()

  const HeaderInner = headerInner
  useGetOriginUser()
  useEffect(() => {
    YaMetricHit(window.location.href) //TODO нужен ли здесь?
  }, [])

  useEffect(() => {
    if (isSmooth) {
      document.documentElement.style.scrollBehavior = 'smooth'
    } else document.documentElement.style.scrollBehavior = 'initial'
  }, [isSmooth])

  useEffect(() => {
    const isInformLineAvailable =
      informData?.name &&
      !informData.lineClosed &&
      !noInformLine &&
      isLoaded &&
      !isPageWithActiveWebinar

    if (!isInformLineAvailable) return
    if (window.scrollY === 0) setInformLineHide(false)

    const toggleInformLineVisible = () => {
      if (informData) {
        if (window.scrollY > 0) {
          setInformLineHide(true)
        } else {
          setInformLineHide(false)
        }
      }
    }

    window.addEventListener(Events.SCROLL, toggleInformLineVisible)
    return () => window.removeEventListener(Events.SCROLL, toggleInformLineVisible)
  }, [informData, noInformLine, isLoaded])

  useEffect(() => {
    const setPropOnScroll = () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
    }
    window.addEventListener(Events.SCROLL, setPropOnScroll)
    return () => window.removeEventListener(Events.SCROLL, setPropOnScroll)
  }, [])

  const showRatingTable =
    !isPageWithActiveWebinar &&
    unratedWebinar?.webinarId &&
    !unratedWebinar?.rating?.close &&
    !noRaitingTable

  const btnUpStyles = showRatingTable ? styles.btnUp : btnUpModificator

  return (
    <>
      {currentTitle && (
        <Head>
          <title>{currentTitle}</title>
        </Head>
      )}

      <div
        className={clsx(styles.container, {
          [styles.isAbsolute]: isAbsolute,
          [styles.fullScreenHeight]: isScreenHeight,
          [modificator as string]: modificator,
        })}
      >
        {!noHeader && (
          <Navbar
            noLines={noLines}
            isInformLineHide={isInformLineHide}
            informData={informData}
            isNoAuth={isNoAuth}
            noNavbarItems={noNavbarItems}
          >
            {HeaderInner && <HeaderInner />}
          </Navbar>
        )}

        <main
          style={
            isAbsolute
              ? {
                  marginTop: `${navbarHeight}px`,
                  height: `calc(100% - ${navbarHeight}px)`,
                }
              : { marginTop: `${navbarHeight}px` }
          }
          className={clsx(styles.components, { [styles.mainPage]: mainPage })}
        >
          {children}
          {showRatingTable && (
            <RatingTable
              webinarId={unratedWebinar.webinarId}
              isPast
              date={unratedWebinar.webinarDate}
              lecturs={unratedWebinar.Lecturs}
              origin={ORIGIN_PAST}
            />
          )}
        </main>
        <Cookies />
        {!noFooter && <Footer />}
        {/* {!noAttention && <AttentionPopup />} */}
        {isAuthorized && <PopupBrokenMail />}
        {!isOnline && <NoInternetConnection />}
        {isBannerTelegramBottomOpen && <BannerTelegramBottom />}

        <HashPopup />
        <AlertWrapper />
      </div>
    </>
  )
}

export default MainLayout
