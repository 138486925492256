import { FC, memo } from 'react'
import dynamic from 'next/dynamic'

import Auth from 'store/auth'
const NavbarNoAuth = dynamic(() => import('./navbar-no-auth'))
const NavbarAuthed = dynamic(() => import('./navbar-authed'))

import { INavbarRightSide } from './navbar-right-side.type'

const NavbarRightSide: FC<INavbarRightSide> = ({
  openedMenu,
  handleMenuState,
  isCongress,
  isNoAuth,
}: INavbarRightSide) => {
  const { isAuthorized } = Auth.useAuth()

  return isAuthorized ? (
    <NavbarAuthed openedMenu={openedMenu} handleMenuState={handleMenuState} />
  ) : (
    <NavbarNoAuth congressTheme={isCongress} isNoAuth={isNoAuth} />
  )
}

export default memo(NavbarRightSide)
