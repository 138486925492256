import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAuth } from 'store/auth/useAuth'
import { putAxiosSingle } from 'pages/api/axios'
import { profileURL } from 'constants/url'
import { LocalStorageKeys } from 'constants/storage'

const useGetOriginUser = () => {
  const { query } = useRouter()
  const { isAuthorized, userProfile } = useAuth()

  useEffect(() => {
    const originOuter = query['utm_source'] || ''
    const setTags = async (tag: string) => {
      localStorage.setItem(LocalStorageKeys.USER_ORIGIN, '')
      await putAxiosSingle(profileURL, {}, { addTags: [tag] })
    }

    if (originOuter) localStorage.setItem(LocalStorageKeys.USER_ORIGIN, String(originOuter))
    const localOrigin = localStorage.getItem(LocalStorageKeys.USER_ORIGIN)

    if ((localOrigin || originOuter) && isAuthorized) {
      const currentTag = String(localOrigin ?? originOuter)
      setTags(currentTag)
    }
  }, [userProfile.id])
}

export default useGetOriginUser
