import { STATIC_URL } from 'constants/data'

const logoIcon = `${STATIC_URL}/navbar/logo.svg`
// const logoIcon = `${STATIC_URL}/navbar/logo_ny.svg`
const logoIconCollapsed = `${STATIC_URL}/navbar/logo-collapsed.svg`

const logoIconSize = {
  small: { width: 120, height: 26 },
  big: { width: 179, height: 40 },
  // small: { width: 140, height: 35 },
  // big: { width: 179, height: 45 },
}

// const logoIconSize = {
//   small: { width: 130, height: 37 },
//   big: { width: 186, height: 53 },
// }

const logoIconCollapsedSize = { width: 26, height: 28 }

export { logoIcon, logoIconSize, logoIconCollapsed, logoIconCollapsedSize }
