import { FC, ChangeEvent } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import clsx from 'clsx'

import useSearch from 'store/navbar/useSearch'

import { arrowIconBlack, iconSearch } from '../data'

import style from './search-field.module.scss'

interface IProps {
  closeMenu: () => void
}

const SearchField: FC<IProps> = ({ closeMenu }: IProps) => {
  const { searchText, setSearchText } = useSearch()
  const router = useRouter()

  const handleSetSearchStr = (e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      router.push(`/search?s=${searchText.trim()}`)
      closeMenu()
    }
  }

  return (
    <div className={style.searchInputWrapper}>
      <input
        className={style.searchInput}
        placeholder="Введите поисковый запрос..."
        onChange={handleSetSearchStr}
        onKeyDown={handleEnter}
        autoFocus={true}
        value={searchText}
      />
      <Link href={`/search?s=${searchText.trim()}`} prefetch={false}>
        <img
          decoding="async"
          loading="lazy"
          className={clsx(style.btnSearch, style.btnSearchArrow)}
          src={arrowIconBlack}
          onClick={closeMenu}
          alt="search icon"
        />
        <img
          decoding="async"
          loading="lazy"
          className={clsx(style.btnSearch, style.btnSearchLupa)}
          src={iconSearch}
          onClick={closeMenu}
          alt="search icon"
        />
      </Link>
    </div>
  )
}

export default SearchField
