import { FC } from 'react'
import clsx from 'clsx'
import Image from 'next/image'

import { Phones, imgData } from './data'

import styles from './phone.module.scss'

interface IPhone {
  modificator?: string
}
const Phone: FC<IPhone> = ({ modificator }) => {
  return (
    <a
      href="tel:+74992261847"
      className={clsx(styles.phoneWrap, {
        [modificator as string]: modificator,
      })}
    >
      <Image className={styles.phoneIcon} {...imgData.phoneIcon} />
      <p className={styles.phoneText}>{Phones.MAIN}</p>
    </a>
  )
}

export default Phone
