import { useEffect } from 'react'

import Auth from 'store/auth'
import useAlert from 'store/common/useAlert'

import { SessionStorageKeys } from 'constants/storage'
import { AlertType } from 'constants/alert'

const useLocationCheck = () => {
  const { setSingleAlert } = useAlert()
  const { userProfile, isLoaded } = Auth.useAuth()

  useEffect(() => {
    if (isLoaded) {
      if (sessionStorage.getItem(SessionStorageKeys.DONT_ASK_LOCATION) === 'true') return
      else if (userProfile.needConfirmPlace)
        setSingleAlert({
          type: AlertType.GUESS_CITY,
          id: AlertType.GUESS_CITY,
        })
    }
  }, [isLoaded])
}

export default useLocationCheck
