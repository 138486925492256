import { FC, useState, useEffect } from 'react'

import CookieModal from './cookies-modal'

import { LocalStorageKeys } from 'constants/storage'

const Cookies: FC = () => {
  const [isHidden, setHidden] = useState(true)

  const closeCookie = () => {
    localStorage.setItem(LocalStorageKeys.COOKIES_AGREE, 'true')
    setHidden(true)
  }

  useEffect(() => {
    const currentCookie = Boolean(localStorage.getItem(LocalStorageKeys.COOKIES_AGREE))
    const oldCookie = Boolean(localStorage.getItem(LocalStorageKeys.COOKIES_AGREE_OLD))
    if (oldCookie && !currentCookie) localStorage.setItem(LocalStorageKeys.COOKIES_AGREE, 'true')
    setHidden(oldCookie || currentCookie)
  }, [])

  return <>{!isHidden && <CookieModal closeCookie={closeCookie} />}</>
}

export default Cookies
