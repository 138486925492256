import { useEffect, useState } from 'react'

import { Events } from 'constants/events'

export const ScreenOrientation = {
  horizontal: 'horizontally',
  vertical: 'vertically',
}

const useDeviceOrientation = () => {
  const [orientation, setOrientation] = useState(ScreenOrientation.vertical)

  useEffect(() => {
    const defineOrientation = () => {
      const query = window.matchMedia('(orientation:landscape)')
      const currOrientation = query.matches
        ? ScreenOrientation.horizontal
        : ScreenOrientation.vertical
      setOrientation(currOrientation)
    }
    defineOrientation()
    window.addEventListener(Events.RESIZE, defineOrientation)
    return () => window.removeEventListener(Events.RESIZE, defineOrientation)
  }, []) //ориентация мобильного устройства

  return orientation
}

export default useDeviceOrientation
